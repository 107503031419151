import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PartnerListCard from "../../components/PartnerListCard/PartnerListCard";
import { PartnerListingPageContainer } from "../PartnerListingPage/PartnerListingPageContainer";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import usePartnerList from "../../Hooks/usePartnerList";
import { Helmet } from "react-helmet";

const CategoryWisePartnerListingPage = () => {
  const partnerList = useSelector((state) => state.partnerList);
  const cityName = localStorage.getItem("partnerCity") || "";
  const offerId = localStorage.getItem("offerId") || 0;

  const { getPartnerList } = usePartnerList();

  const navigate = useNavigate();
  const location = useLocation();
  const categoryId = location.pathname.split("/").pop();

  useEffect(() => {
    getPartnerList(0, 100, cityName, categoryId, offerId);
  }, [cityName, categoryId, offerId]);

  const handleCardClick = (partnerObj) => {
    const partnerName = partnerObj?.partnerName
      .split(" ")
      .join("_")
      .toLowerCase();
    navigate(`/partner/${partnerName}/${partnerObj?.partnerId}`);
  };

  const helmetData = {
    Restaurant_And_Cafe: {
      title: "offer for restaurant & Cafe | restaurant in Morbi - SmaartCard",
      description:
        "Good news for morbi. With our smaartcard you get food Offer, pizza discounts, restaurant offers in Rajkot, Ahmedabad, Surat, Junagadh, & Jamnagar. contact us. ",
      keywords:
        "offer for restaurant, pizza hut coupons, food coupons, food offers near me, pizza discounts, restaurant offers in ahmedabad",
      link: "https://smaartcard.com/category/Restaurant_And_Cafe/45",
    },
    Hospital_And_Clinic: {
      title: "Exclusive Offers for Hospitals | Healthcare Discounts & Deals",
      description:
        "Discover our offer for hospitals, featuring exceptional discounts and benefits on a wide range of medical services. Explore now!",
      keywords: "offer for clinic, best deal, discounts, clinic offer",
      link: "https://smaartcard.com/category/Hospital_And_Clinic/52",
    },
    Super_Market_And_Bakery_And_Grocery: {
      title: "Supermarket Offers in Morbi | Save Big on Your Shopping Today",
      description:
        "Explore the latest supermarket offers in Morbi and enjoy fantastic discounts on groceries, household essentials, and more.",
      keywords:
        "Supermarket Offers in Morbi, Offer in Shopping, offers and discounts",
      link: "https://smaartcard.com/category/Super_Market_And_Bakery_And_Grocery/47",
    },
    Gym_Physio_And_Fitness: {
      title:
        "Offer on Physio and Fitness Center Memberships - Limited Time Only!",
      description:
        "Our special offer at the Physio and Fitness Center. Enjoy top-notch physiotherapy and fitness programs designed to boost your health and well-being.",
      keywords:
        "Physio and Fitness Center, free coupons, offers and discounts, best discount offers",
      link: "https://smaartcard.com/category/Gym_Physio_And_Fitness/46",
    },
    Hospital: {
      title: "Exclusive Offers for Hospitals | Healthcare Discounts & Deals",
      description:
        "Discover our offer for hospitals, featuring exceptional discounts and benefits on a wide range of medical services. Explore now!",
      keywords:
        "Offers for Hospitals, best deal, discounts, discounts and benefits, times prime membership, discount app",
      link: "https://smaartcard.com/category/hospital",
    },
    Home_decor_and_Furniture: {
      title:
        "Exclusive Offer on Home Decor and Furniture - Transform Your Space Today",
      description:
        "Offers on stylish home decor and furniture. Shop now to enhance your living space with premium designs at discounted prices. Limited time offer!",
      keywords:
        "Offer on Home Decor and Furniture, discounted prices, Limited time offer",
      link: "https://smaartcard.com/category/Home_decor_and_Furniture/31",
    },
    Game_Zone: {
      title: "Exciting Offers in Game Zone - Unleash the Fun and Save Big!",
      description:
        "Discover the best offers in Game Zone! Enjoy exclusive discounts and special deals on top-rated games, accessories, and more.",
      keywords: "Offer in Game Zone, offers, amazon discount codes, promo code",
      link: "https://smaartcard.com/category/Game_Zone/30",
    },
    Jewellers: {
      title:
        "Exclusive Offers on Jewellery | Unbeatable Deals on Stunning Pieces",
      description:
        "Discover the best offers in jewellery with our exclusive deals on rings, necklaces, bracelets, and more. Shop now to find the perfect piece at an Best price!",
      keywords:
        "Offers on Jewellery, Offer in Shopping, free gift cards, Jewellry offer",
      link: "https://smaartcard.com/category/Jewellers/34",
    },
    Pharmacy_or_Medical_Store: {
      title:
        "Exclusive Offer on Medicines - Save Big on Your Prescriptions Today!",
      description:
        "Discover offers on medicines at our online pharmacy. Enjoy discounts and fast delivery on a wide range of prescription and over-the-counter medications.",
      keywords:
        "Offer on Medicines, offer for hospital, discount app, free coupons",
      link: "https://smaartcard.com/category/Pharmacy_or_Medical_Store/11",
    },
    Laboratory: {
      title: "Get Offers on Laboratory with smaartcard",
      description:
        "Explore unbeatable offers on laboratory services. Grab exclusive discounts now!!",
      keywords:
        "Offers on Laboratory, offers on blood report, Offer in reports",
      link: "https://smaartcard.com/category/Laboratory/10",
    },
    beauty_and_fashion: {
      title: "Get Offers on beauty and fashion | Smaartcard",
      description:
        "Explore unbeatable deals and discounts on the latest beauty and fashion trends. Hurry, grab the hottest deals before they're gone!",
      keywords:
        "beauty and fashion, Offer in Shopping, best discount offers, free gift cards",
      link: "https://smaartcard.com/category/beauty_and_fashion/22",
    },
    Garments_And_Footwear: {
      title: "Exclusive offers on garments & Footwear | Morbi & Ahmedabad",
      description:
        "Discover exclusive deals on garments & footwear in Morbi & Ahmedabad. Shop quality products at unbeatable prices. Don't miss out—grab your offers today!",
      keywords:
        "offers on garments & Footwear, Morbi, Ahmedabad, grab your offers",
      link: "https://smaartcard.com/category/Garments_And_Footwear/48",
    },
    Hobby_And_Education: {
      title: "Get offer on Hobby & Education | Smaartcard",
      description:
        "Explore exciting offers on Hobby & Education with Smaartcard. Unlock exclusive deals to fuel your passions and learning journey. Start saving today!",
      keywords:
        "offers on garments & Footwear, Morbi, Ahmedabad, grab your offers",
      link: "https://smaartcard.com/category/Hobby_And_Education/54",
    },

    Gift_Articles_And_School_Bags: {
      title: "Get offer on Gift Articles & School Bags in Morbi",
      description:
        "Shop Gift Articles & School Bags in Morbi with exciting offers! Quality products at unbeatable prices. Don't miss out—visit us today!",
      keywords:
        "Gift Articles & School Bags in Morbi, Get offer on Gift Articles & School Bags, offers in junagadh, Offer in Shopping",
      link: "https://smaartcard.com/category/Gift_Articles_And_School_Bags/43",
    },

    Software: {
      title: "Unlock Savings with Exclusive Discounts on Software | Smaartcard",
      description:
        "Discover unbeatable discounts on top-rated software solutions! Explore our curated selection of discounted software offers now.",
      keywords:
        "Discounts on Software, discount app, free coupons, Software morbi",
      link: "https://smaartcard.com/category/software",
    },

    Fitness_Equipment: {
      title: "Explore Discounts on Fitness Equipment Today",
      description:
        "Discover great discounts on high-quality fitness equipment to kickstart your journey towards a healthier lifestyle.",
      keywords:
        "Discounts on Fitness Equipment, Offer in Shopping, Offer in gym, gym",
      link: "https://smaartcard.com/category/Fitness_Equipment/19",
    },

    Hotel_Resort_And_Water_Park: {
      title: "Explore Exciting Offers at Waterpark & Resort",
      description:
        "Dive into adventure and relaxation with our exclusive offers at the waterpark & resort. Discover thrilling discounts, family packages, and more for the ultimate aquatic getaway.",
      keywords:
        "Offers at Our Waterpark & Resort, Offers in Waterpark, Offers in Resort, vacation Offer",
      link: "https://smaartcard.com/category/Hotel_Resort_And_Water_Park/53",
    },

    Electronics: {
      title: "Explore Irresistible Offers on Electronics Products",
      description:
        "Exclusive deals and discounts on a wide range of electronics products. From smartphones to laptops, find unbeatable offers to elevate your tech game.",
      keywords:
        "Offers on Electronics Products, Offer in Shopping, Offers on Mobile, Offers on Laptop.",
      link: "https://smaartcard.com/category/Electronics/25",
    },

    Hotel: {
      title: "Get Offer in Hotel at Morbi, Rajkot & Ahmedabad | Smaartcards",
      description:
        "Discover exclusive hotel offers in Morbi, Rajkot, & Ahmedabad with Smaartcard. Save on stays and enjoy premium benefits. Book now for the best deals!",
      keywords:
        "hotel offers in Morbi, hotel offers in Rajkot, hotel offers in Ahmedabad, best deals",
      link: "https://smaartcard.com/category/Hotel/36",
    },

    Eyewear: {
      title: "Exclusive offers on Eyewear | offer on chasma | Smaatcard",
      description:
        "Grab exclusive offers on eyewear and chasma with Smaatcard. Enjoy stylish and affordable options at unbeatable prices. Shop now and save big!",
      keywords: "offers on Eyewear, offer on chasma, Smaatcard, Offer",
      link: "https://smaartcard.com/category/Eyewear/51",
    },

    Automobile: {
      title: "Offer on Automobile | Morbi, Ahmedabad & Rajkot",
      description:
        "Exclusive automobile deals in Morbi, Ahmedabad & Rajkot! Grab the best offers now and drive home your dream vehicle. Don't miss out—limited time only!",
      keywords:
        "Offer on Automobile, Automobile offer in morbi, Automobile offer in Rajkot, Automobile offer in ahmedabad,",
      link: "https://smaartcard.com/category/Automobile/491",
    },

    Fuel: {
      title: "Get Offer on Petroleum | Get Offer in Morbi | Smaartcard",
      description:
        "Exclusive offers on petroleum products in Morbi with Smaartcard. Unlock special discounts and deals today!",
      keywords:
        "Offer on Petroleum, Get Offer in Morbi, offers on petroleum products, petroleum products in Morbi",
      link: "https://smaartcard.com/category/Fuel/35",
    },

    Ayurveda_and_Naturocare_and_Wellness: {
      title: "offer on Ayurvedic Therapy | offer on Ayurvedic Products - Morbi",
      description:
        "Exclusive offers on Ayurvedic Therapy and products in Morbi. Discover natural wellness solutions and enjoy great discounts on premium Ayurvedic items!",
      keywords:
        "offer on Ayurvedic Therapy, Ayurvedic products in Morbi, Offer in Morbi",
      link: "https://smaartcard.com/category/Ayurveda_and_Naturocare_and_Wellness/37",
    },

    cafe: {
      title: "Cafe Offers in Morbi - Indulge in Delicious Deals Today!",
      description:
        "Explore the latest cafe offers in Morbi! From discounts on gourmet coffee to tempting combo deals, find the perfect spot to satisfy your cravings.",
      keywords:
        "Cafe Offers in Morbi, offer for restaurant, pizza hut coupons, food coupons",
      link: "https://smaartcard.com/category/cafe",
    },

    "Loan and Insurance": {
      title:
        "Unlock Incredible Deals: Loan and Insurance Services Offers Await!",
      description:
        "Discover unbeatable offers on loan and insurance services tailored to your needs. Don't miss out on exclusive deals and savings. Explore now!",
      keywords:
        "Loan and Insurance Services Offers, Offers in Loan and Insurance Services, offers and discounts",
      link: "https://smaartcard.com/category/loan_and_insurance",
    },
  };

  // console.log("category", location.pathname.split("/")[2]);

  const data = helmetData[location.pathname.split("/")[2]];

  return (
    <PartnerListingPageContainer>
      {data && (
        <Helmet>
          <title>{data.title}</title>
          <meta name="description" content={data.description} />
          <meta name="keywords" content={data.keywords} />
          <Link rel="canonical" href={data.link} />
          <meta name="author" content="Smaart card" />
          <meta property="og:title" content="Partner Listing Page" />
          <meta
            property="og:description"
            content="Discover our trusted partners and learn more about them."
          />
          <meta property="og:type" content="website" />
        </Helmet>
      )}
      <Header />
      <div className="allContent">
        <PartnerListCard
          partners={partnerList?.partners}
          handleCardClick={handleCardClick}
        />
      </div>
      <Footer />
    </PartnerListingPageContainer>
  );
};

export default CategoryWisePartnerListingPage;
