import React, { useEffect, useState } from "react";
import { HeaderContainer } from "./HeaderContainer";
import { FaSearch, FaUserCircle } from "react-icons/fa";
import logo from "../../assets/images/company_logo.png";
import CompanyLogo from "../Logo/CompanyLogo";
import { useNavigate } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useDispatch, useSelector } from "react-redux";
import { resetUserData } from "../../store/User/UserSlice";
import { usePartnerCity } from "../../Hooks/usePartnerCity";
import { resetPartnerData } from "../../store/Partner/PartnerSlice";
import { swalUtils } from "../../utils/swalUtils";
import usePartnerList from "../../Hooks/usePartnerList";
import { axiosFunction } from "../../axiosFunction";
import { smaartCardUrl } from "../../constant";
import { toastMessage } from "../../utils/toastUtils";
import Loader from "../Loader/Loader";
import CommonModal from "../Modal/CommonModal";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import Input from "../Input/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import imgLogo from "../../assets/images/imagePlaceholder.png";
import useResponsive from "../../Hooks/useResponsive";
import defaultImg from "../../assets/images/imagePlaceholder.png";
import { Helmet } from "react-helmet";

const Header = () => {
  const navigate = useNavigate();
  const { city } = usePartnerCity();

  const offerId = localStorage.getItem("offerId") || 0;
  const role = localStorage.getItem("role");

  const [cityValue, setCityValue] = useState(
    localStorage.getItem("partnerCity")
  );
  const [activeTab, setActiveTab] = useState("Home");

  const [showUserDropDown, setShowUserDropDown] = useState(false);
  const [showPartnerDropDown, setShowPartnerDropDown] = useState(false);
  const [showChangePasswordDropDown, setShowChangePasswordDropDown] =
    useState(false);

  const { getPartnerList } = usePartnerList();

  const onCityChange = (e) => {
    setCityValue(e.target.value);
    localStorage.setItem("partnerCity", e.target.value);
    getPartnerList(0, 100, e.target.value, 0, offerId);
  };

  const { isMobile, isRespon } = useResponsive();
  const userId = localStorage.getItem("userId");
  const partnerId = localStorage.getItem("partnerId");

  const userData = useSelector((state) => state.userData);
  const partnerData = useSelector((state) => state.partnerData);
  const generalSetting = useSelector((state) => state.generalSetting);
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value === "") {
      setSearchResults([]);
      return;
    }

    const searchRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/partner/SearchPartner`,
      "POST",
      {
        searchValue: value,
        cityName: localStorage.getItem("partnerCity"),
      }
    );

    if (searchRes?.data?.status) {
      setSearchResults(searchRes?.data?.response);
    } else {
      setSearchResults([]);
    }
  };

  const changePasswordSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .test("hasNumber", "Password must contain at least one number", (value) =>
        /\d/.test(value)
      )
      .test(
        "hasUppercase",
        "Password must contain at least one uppercase letter",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "hasLowercase",
        "Password must contain at least one lowercase letter",
        (value) => /[a-z]/.test(value)
      )
      .test(
        "hasSpecialChar",
        "Password must contain at least one special character",
        (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
      )
      .required("Old Password is required"),
    newPassword: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .test("hasNumber", "Password must contain at least one number", (value) =>
        /\d/.test(value)
      )
      .test(
        "hasUppercase",
        "Password must contain at least one uppercase letter",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "hasLowercase",
        "Password must contain at least one lowercase letter",
        (value) => /[a-z]/.test(value)
      )
      .test(
        "hasSpecialChar",
        "Password must contain at least one special character",
        (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
      )
      .required("New Password is required"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .min(8, "Password must be at least 8 characters long")
      .test("hasNumber", "Password must contain at least one number", (value) =>
        /\d/.test(value)
      )
      .test(
        "hasUppercase",
        "Password must contain at least one uppercase letter",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "hasLowercase",
        "Password must contain at least one lowercase letter",
        (value) => /[a-z]/.test(value)
      )
      .test(
        "hasSpecialChar",
        "Password must contain at least one special character",
        (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
      )
      .required("Confirm Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      confirmPassword: "",
      newPassword: "",
      oldPassword: "",
    },
    resolver: yupResolver(changePasswordSchema),
    mode: "onChange",
  });

  const handleLogout = async () => {
    const res = await swalUtils(
      "Are you sure?",
      "You are about to log out. Are you sure you want to proceed?",
      "Cancel",
      "Yes, Log me out!"
    );

    if (res.dismiss === "cancel" && res.isDismissed) {
      navigate("/login");
      localStorage.clear();
      dispatch(resetUserData());
      dispatch(resetPartnerData());
    }
  };

  const handleUnSubscribe = async () => {
    const res = await swalUtils(
      "Are you sure?",
      "You will be unsubscribed!",
      "Cancel",
      "Yes, Unsubscribe!"
    );

    if (res.dismiss === "cancel" && res.isDismissed) {
      const res = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/user/deactivete/${
          userId ? userId : partnerId
        }`,
        "DELETE",
        null
      );

      if (res?.data?.status) {
        toastMessage("You have been unsubscribed!", "success");
        dispatch(resetPartnerData());
        dispatch(resetUserData());
        localStorage.removeItem("userId");
        localStorage.removeItem("role");
        localStorage.removeItem("partnerId");
        navigate("/login");
      }
    }
  };

  const navMenuArr = [
    {
      id: 1,
      name: "Home",
      path: role === "partner" ? "/partner-profile" : "/",
    },
    { id: 2, name: "About Us", path: "/AboutUs" },
    { id: 3, name: "Contact Us", path: "/ContactUs" },
    // { id: 4, name: "Blog", path: "/mk" },
  ];

  const handleMenuClick = (menu) => {
    navigate(menu?.path);
  };

  const handleChangePassword = () => {
    setShowChangePasswordDropDown(!showChangePasswordDropDown);
  };

  const handleClosePasswordDropDown = () => {
    reset();
    setShowChangePasswordDropDown(!showChangePasswordDropDown);
  };

  const onSubmit = async (data) => {
    const passRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/partner/changepassword`,
      "POST",
      {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
        phone: partnerData?.mobile || "",
      }
    );

    if (passRes?.data?.status) {
      toastMessage(passRes?.data?.message, "success");
      navigate("/partnerRegistration");
      localStorage.removeItem("role");
      localStorage.removeItem("partnerId");
    } else {
      toastMessage(passRes?.data?.message, "error");
    }
  };

  const modalContent = () => {
    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Input
              type={"password"}
              control={control}
              register={register}
              nameField={"oldPassword"}
              errors={errors}
              label={"Old Password"}
            />

            <Input
              type={"password"}
              control={control}
              register={register}
              nameField={"newPassword"}
              errors={errors}
              label={"New Password"}
            />

            <Input
              type={"password"}
              control={control}
              register={register}
              nameField={"confirmPassword"}
              errors={errors}
              label={"Confirm Password"}
            />

            <Col as={Col} md="12" className="text-center mt-3">
              <Button
                type="submit"
                variant="primary"
                style={{ background: "#024089", borderColor: "#024089" }}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      </>
    );
  };

  const handleGetHelp = async () => {
    const res = await swalUtils(
      "Contact Us",
      "1st floor, above Mr Handsome store, opp. Sky mall, sanala road, Morbi - 363641 Gujarat",
      "Email",
      "Phone"
    );

    if (res.dismiss === "cancel" && res.isDismissed) {
      const phoneNumber = generalSetting?.find(
        (setting) => setting?.type === "Phone"
      )?.value;

      const phoneLink = `tel:${encodeURIComponent(phoneNumber)}`;
      window.location.href = phoneLink;
    }

    if (res.isConfirmed) {
      const emailAddress = generalSetting?.find(
        (setting) => setting?.type === "Email"
      )?.value;

      const emailSubject = "Regarding your services";
      const emailBody = "Hello, I would like to inquire about your services...";
      const mailtoLink = `mailto:${encodeURIComponent(
        emailAddress
      )}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(
        emailBody
      )}`;
      window.location.href = mailtoLink;
    }
  };

  const handleLoginUser = () => {
    if (userId) {
      setShowUserDropDown(true);
    } else if (partnerId) {
      setShowPartnerDropDown(true);
    } else {
      navigate("/login");
      localStorage.removeItem("userId");
      localStorage.removeItem("role");
      localStorage.removeItem("partnerId");
      dispatch(resetUserData());
      dispatch(resetPartnerData());
    }
  };

  const handleNavigation = (path, tabName) => {
    setActiveTab(tabName);
    navigate(path);
  };

  return (
    <HeaderContainer className="py-lg-3 pt-2 pb-1">
      {window.location.pathname === "/" && (
        <Helmet>
          <title>offers and discounts in Gujarat| SmaartCard</title>
          <meta
            name="description"
            content="With our smaartcrad and get best offers on restaurants, Offer in Game Zone, Offer in Shopping. also get offer in Rajkot, Ahmedabad, Surat, Junagadh, & Jamnagar."
          />
          <meta
            name="keywords"
            content="best offers, offers and discounts, best offers on restaurants, offers and discounts in Gujarat, offers on restaurants, offer in Rajkot, offer in ahmedabad"
          />
          <meta name="author" content="Smaart card" />
          <meta property="og:title" content="Smaart Card" />
          <meta
            property="og:description"
            content="Discover our trusted partners and learn more about them."
          />
          <meta property="og:type" content="website" />
        </Helmet>
      )}
      <Container>
        <Row className="mb-2">
          <Col md={2} xs={2} className="d-lg-none d-flex">
            <Navbar
              key={"manan"}
              expand={"expand"}
              className=" mb-lg-3 d-lg-none d-unset"
            >
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${"expand"}`}
              />

              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${"expand"}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${"expand"}`}
                placement="start"
              >
                <Offcanvas.Header
                  closeButton
                  closeVariant="white"
                  className="mobileHeader close"
                  style={{ background: "#024089" }}
                >
                  <Offcanvas.Title
                    id={`offcanvasNavbarLabel-expand-${"expand"}`}
                  >
                    <CompanyLogo
                      companyLogo={logo}
                      style={{ height: isMobile && isRespon && "30px" }}
                      className="inner-logo kpMob img-fluid"
                    />
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body style={{ paddingLeft: 0, paddingRight: 0 }}>
                  <Nav className="justify-content-end flex-grow-1 kp">
                    {navMenuArr?.map((menu) => {
                      return (
                        <Nav.Link
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            background:
                              window.location.pathname === menu.path
                                ? "#cecece"
                                : "",
                          }}
                          className={
                            window.location.pathname === menu.path
                              ? "active"
                              : ""
                          }
                          key={menu.id}
                          onClick={() => handleMenuClick(menu)}
                        >
                          {menu?.name}
                        </Nav.Link>
                      );
                    })}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Navbar>
          </Col>
          <Col lg={2} xs={4} className="customRow justify-content-start">
            <CompanyLogo
              onClick={() =>
                role === "partner"
                  ? navigate("/partner-profile")
                  : navigate("/")
              }
              style={{ cursor: "pointer" }}
              companyLogo={logo}
              className="inner-logo img-fluid"
            />
          </Col>
          <Col lg={8} className="headbox mt-lg-0 mt-1">
            {role !== "partner" && (
              <Row>
                <Col lg={4} xs={5}>
                  <Form.Select
                    aria-label="Default select example"
                    className="headselect"
                    value={cityValue}
                    onChange={onCityChange}
                  >
                    <option value="">Select City</option>
                    {city?.map((ct) => {
                      return (
                        <option key={ct.cityId} value={ct.cityName}>
                          {ct.cityName}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>
                <Col lg={8} xs={7}>
                  <div className="search">
                    <input
                      className="search-input"
                      type="text"
                      placeholder="Search"
                      value={searchValue}
                      onChange={handleSearch}
                    />
                    <div className="search-icon">
                      <FaSearch />
                    </div>
                    <div
                      style={{
                        display: searchResults?.length === 0 ? "none" : "",
                      }}
                      className="search-result"
                    >
                      {searchValue !== "" && searchResults?.length === 0 ? (
                        <div className="no-result">No records found</div>
                      ) : (
                        searchResults?.map((data) => (
                          <div
                            onClick={() =>
                              navigate(
                                `/partner/${data?.partnerName
                                  .split(" ")
                                  .join("_")
                                  .toLowerCase()}/${data?.partnerId}`
                              )
                            }
                            className="result-list"
                          >
                            <img
                              width={isMobile && isRespon ? 35 : 50}
                              height={isMobile && isRespon ? 35 : 50}
                              src={data?.profilePhoto || imgLogo}
                              alt="Default_photo"
                            />

                            <div className="result-details">
                              <div>
                                <span>{data?.partnerName}</span>
                              </div>

                              <div className="d-flex sub-details">
                                <p className="type-data">
                                  Type: <span>{data?.typeName || "-"}</span>
                                </p>

                                <p className="city-data">
                                  City: <span>{data?.city || "-"}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
          <Col lg={2} xs={6} className="customRow">
            <div onClick={handleLoginUser} className="profile-icon">
              <Dropdown align="end">
                <Dropdown.Toggle
                  variant="link"
                  className="d-flex align-items-center p-0"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {role === "user" &&
                  (userData?.logo || userData?.patientLogo) ? (
                    <img
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                      }}
                      src={
                        userData?.logo || userData?.patientLogo || defaultImg
                      }
                      alt="pateint_profile_img"
                    />
                  ) : role === "partner" ? (
                    <img
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                      }}
                      alt="pateint_profile_img"
                      src={partnerData?.profilePhoto || defaultImg}
                    />
                  ) : (
                    <FaUserCircle className="headuserIcon" />
                  )}
                  <span className="profileName">
                    {userId
                      ? userData?.name
                      : partnerId
                      ? partnerData?.name
                      : "Login/Signup"}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <>
                    {showUserDropDown && (
                      <Dropdown.Item onClick={() => navigate("/myprofile")}>
                        Profile
                      </Dropdown.Item>
                    )}

                    <Dropdown.Item onClick={handleGetHelp}>
                      Get Help
                    </Dropdown.Item>

                    {/* {showUserDropDown && (
                      <Dropdown.Item onClick={handleUnSubscribe}>
                        Unsubscribe
                      </Dropdown.Item>
                    )} */}
                    {showPartnerDropDown && (
                      <>
                        <Dropdown.Item onClick={handleChangePassword}>
                          Change Password
                        </Dropdown.Item>
                        <CommonModal
                          modalTitle={"Change Password"}
                          show={showChangePasswordDropDown}
                          handleClose={handleClosePasswordDropDown}
                          modalBody={modalContent()}
                          modalSize={"md"}
                        />
                      </>
                    )}
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </>
                </Dropdown.Menu>
              </Dropdown>

              {/* </span> */}
            </div>
          </Col>
        </Row>
        <Row>
          <div className="top-section d-lg-inline-block d-none">
            {navMenuArr?.map((menu) => {
              return (
                <span
                  onClick={() => handleMenuClick(menu)}
                  className={`nav-headerKp ${
                    window.location.pathname === menu.path ? "active" : ""
                  }`}
                >
                  {menu?.name}
                </span>
              );
            })}
          </div>
        </Row>
      </Container>
    </HeaderContainer>
  );
};

export default Header;
