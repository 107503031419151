import { styled } from "styled-components";

export const HomePageContainer = styled.div`
  .imgSlider {
    border-radius: 8px;
    margin: 0 auto;
    @media (max-width: 768px) {
      border-radius: 0;
    }
  }
  .allContent {
    .msection {
      padding: 20px 0;
      @media (max-width: 991px) {
        padding: 10px;
      }
      .sectionMainTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sectionTitle {
          font-size: 26px;
          font-weight: 700;
          color: #1e293b;

          @media (max-width: 991px) {
            font-size: 20px;
          }
        }

        .sectionMore {
          font-size: 16px;
          font-weight: 400;
          color: #1e293b;
          border-bottom: 1px solid #1e293b;
          @media (max-width: 991px) {
            font-size: 14px;
          }
        }
      }

      .card {
        background-color: white;
        border: 1px solid #ccc;
        /* padding: 20px; */
        text-align: center;
        cursor: pointer;
        transition: background-color 0.3s ease;
        &.active {
          background-color: #024089;
          color: #ffff;
          width: 95% !important;
          margin: 0 auto;
        }

        .sub-title {
          font-size: 25px;

          @media (max-width: 1200px) {
            font-size: 22px;
          }
          @media (max-width: 991px) {
            font-size: 20px;
          }
          @media (max-width: 991px) {
            font-size: 18px;
          }
        }

        .price {
          font-size: 30px;
          font-weight: 700;

          @media (max-width: 991px) {
            font-size: 25px;
          }
        }
        .key-main {
          text-align: center;
          .key-title {
            font-size: 20px;
            font-weight: 600;

            @media (max-width: 1199px) {
              font-size: 18px;
              text-align: left;
            }
            @media (max-width: 991px) {
              font-size: 16px;
              text-align: left;
            }
          }
          @media (max-width: 991px) {
            text-align: left;
          }
        }
        .listfeature {
          padding: 0;
        }

        .features-list {
          display: flex;
          align-items: center;
          margin-top: 10px;
          .list {
            display: flex;
            align-items: center;
            gap: 10px;
            .manansvg {
              width: 20px;
              height: 20px;
              @media (max-width: 991px) {
                width: 15px;
                height: 15px;
              }
            }
            p {
              margin-bottom: 0;
              font-size: 14px;
              @media (max-width: 1199px) {
                font-size: 12px;
              }
              @media (max-width: 991px) {
                font-size: 14px;
              }
              @media (max-width: 400px) {
                font-size: 11px;
              }
            }
          }
        }

        .sub-btn {
          color: black;
          background-color: #fff;
          border: 1px solid grey;
          width: 100%;
          font-weight: 700;
          @media (max-width: 575px) {
            color: #024089;
            font-weight: 700;
          }
        }
      }

      .categoryMainCard {
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        margin-top: 20px;
        .card-body {
          position: relative;
          padding: 0;
          .categoryImg {
            height: 230px;
            @media (max-width: 576px) {
              height: 123px;
            }
            img {
              width: 100%;
              height: 100%;
            }
          }
          .categoryContent {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 10px;
            background-color: #f8f8f9;
            opacity: 0.6;
            width: 100%;
            text-align: center;
            @media (max-width: 575px) {
              padding: 5px;
            }
            span {
              color: #000;
              font-weight: 800;
              opacity: 1;
              @media (max-width: 575px) {
                font-size: 12px;
                font-weight: 600;
                display: block;
              }
            }
          }
        }
      }
      .partnerMainCard {
        .partnerCardContent {
          text-align: center;
          @media (max-width: 575px) {
            padding: 10px;
          }
          .partnerImgSection {
            width: 120px;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            img {
            }
            @media (max-width: 575px) {
              width: 80px;
              height: 80px;
            }
          }
          .productSectionTitle {
            font-size: 18px;
            font-weight: 600;
            @media (max-width: 991px) {
              font-size: 15px;
            }
            @media (max-width: 575px) {
              font-size: 12px;
              margin-top: 5px;
            }
          }
        }
      }
      .subscribeSection {
        position: relative;

        .subscribeContentSection {
          margin: 20px 0;
          padding-left: 120px;
          .subscribeContent {
            font-weight: 700;
            font-size: 20px;
            color: #fff;

            @media (max-width: 991px) {
              font-size: 18px;
            }
            @media (max-width: 575px) {
              font-size: 12px;
            }
          }
          .subscribeInput {
            width: 100%;
            height: 50px;
            border-radius: 5px;
            border: none;
            padding: 0 20px;
            @media (max-width: 991px) {
              max-width: 300px;
              padding: 0 10px;
              height: 30px;
            }
            @media (max-width: 767px) {
              height: 35px;
            }
            @media (max-width: 575px) {
              font-size: 10px;
              height: 30px;
            }
          }
          .subscribeBtn {
            width: 100%;
            height: 50px;
            background-color: #f9841a;
            border: 1px solid #f9841a;
            @media (max-width: 991px) {
              max-width: 150px;
              /* margin-top: 10px; */
              height: 40px;
            }
            @media (max-width: 767px) {
              max-width: 150px;
              line-height: 10px;
              height: 35px;
            }
            @media (max-width: 575px) {
              margin-top: 0;
              font-size: 11px;
              height: 30px;
            }
          }
          @media (max-width: 767px) {
            margin: 15px 0;
          }
          @media (max-width: 575px) {
            margin: 6px 0;
            padding-left: 60px;
          }
          @media (max-width: 400px) {
            .btnkp {
              padding: 0;
            }
          }
        }
        .subscribeImgSection {
          position: absolute;
          bottom: -40px;
          left: 0;
          width: 100px;

          @media (max-width: 991px) {
            width: 100px;
            bottom: -30px;
          }
          @media (max-width: 767px) {
            width: 90px;
            bottom: -26px;
          }
          @media (max-width: 575px) {
            width: 60px;
            bottom: -17px;
          }
        }
      }
      .blogMainCard {
        border-radius: 11px;
        .blogImgSection {
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px 10px 0 0;
          }
        }
        .blogCardContent {
          text-align: left;
          .blogT {
            h6 {
              font-weight: 600;
              font-size: 20px;
              @media (max-width: 575px) {
                font-size: 16px;
              }
            }
            .blogP {
              font-size: 14px;
              font-weight: 300;
              color: #1e293b;
              line-height: 17px;
              @media (max-width: 575px) {
                font-size: 12px;
              }
            }
            .readMoreText {
              margin-right: 10px;
              font-weight: 600;
              @media (max-width: 575px) {
                font-size: 14px;
              }
            }
          }
          @media (max-width: 767px) {
            text-align: left;
          }
        }
      }
      .joinSection {
        background: #f0f7ff;
        border-radius: 8px;
        padding: 20px 0;
        .franchiseSection {
          font-size: 28px;
          font-weight: 700;
          line-height: 32px;
          @media (max-width: 991px) {
            font-size: 20px;
            margin-bottom: 10px;
            line-height: inherit;
          }
          @media (max-width: 400px) {
            font-size: 18px;
            margin-bottom: 10px;
          }
        }

        .franchiseInput {
          width: 100%;
          height: 50px;
          border-radius: 5px;
          border: none;
          padding: 0 20px;
          @media (max-width: 991px) {
            padding: 0 8px;
            height: 40px;
          }
          @media (max-width: 400px) {
            padding: 0 8px;
            height: 30px;
            line-height: 10px;
            font-size: 12px;
          }
        }

        .franchiseBtn {
          width: 80%;
          height: 50px;
          background-color: #024089;
          border: 1px solid #024089;
          @media (max-width: 991px) {
            height: 40px;
            padding: 0 8px;
          }
          @media (max-width: 400px) {
            height: 30px;
            padding: 0 8px;
            font-size: 12px;
          }
        }
      }
      .blog-content {
        .accordion-item {
          border: none;
          :hover {
            outline: none;
            border: none;
          }
          :focus {
            border: none;
            border: none;
            background-color: transparent;
            z-index: 0;
          }
        }
      }
      .slick-list .slick-track > div:nth-child(2) {
        /* background-color: red; */
      }

      .subs {
        .slick-slide {
          > div {
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
`;
