import React, { useEffect, useState } from "react";
import { PartnerDetailsPageContainer } from "./PartnerDetailsPageContainer";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { usePartnerDetails } from "../../Hooks/usePartnerDetails";
import { Card, Col, Container, Row } from "react-bootstrap";
import defaultImg from "../../assets/images/imagePlaceholder.png";
import { IoIosCall, IoMdMail } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";
import { smaartCardUrl } from "../../constant";
import { axiosFunction } from "../../axiosFunction";
import Parse from "html-react-parser";
import Loader from "../../components/Loader/Loader";
import useResponsive from "../../Hooks/useResponsive";
import { useSelector } from "react-redux";
import { toastMessage } from "../../utils/toastUtils";
import { swalUtils } from "../../utils/swalUtils";
import AccessDeniedPage from "../AccessDeniedPage/AccessDeniedPage";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

const PartnerDetailsPage = () => {
  const { getPartnerDetails, partnerDetails } = usePartnerDetails();

  const location = useLocation();
  const partnerId = location.pathname.split("/").pop();

  const cardNumberExist = useSelector((state) => state.userData.cardNumber);

  const navigate = useNavigate();

  const { isMobile, isRespon } = useResponsive();

  const userId = localStorage.getItem("userId") || 0;

  const [howtouseData, setHowtouseData] = useState([]);
  const [tcData, setTcData] = useState([]);
  const [packageData, setPackageData] = useState([]);
  const [loadingState, setLoadingState] = useState("");

  const getHowtouseDetails = async () => {
    setLoadingState("howtouse");
    const howtoRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/cmsPages/fetchbyType/HowToUse`,
      "GET",
      null
    );

    if (howtoRes?.data?.status) {
      setLoadingState("");
      setHowtouseData(howtoRes?.data?.response);
    } else {
      setLoadingState("");
      setHowtouseData([]);
    }
  };

  const tcDetails = async () => {
    setLoadingState("tac");
    const tcRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/termsandcondition/${partnerId}/getall`,
      "GET",
      null
    );

    if (tcRes?.data?.status) {
      setLoadingState("");
      setTcData(tcRes?.data?.response?.TermsAndCondition);
    } else {
      setLoadingState("");
      setTcData([]);
    }
  };

  const getPackageDetails = async () => {
    setLoadingState("package");
    const packageRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/master/package/partnerPackagesSmartCard/${partnerId}/${userId}`,
      "GET",
      null
    );

    if (packageRes?.data?.status) {
      setLoadingState("");
      setPackageData(packageRes?.data?.response);
    } else {
      setLoadingState("");
      setPackageData([]);
    }
  };

  useEffect(() => {
    getPartnerDetails(partnerId);
    getHowtouseDetails();
    tcDetails();
    getPackageDetails();
  }, [partnerId]);

  const handleRedeemNowBtn = () => {
    if (userId) {
      if (!cardNumberExist) {
        swalUtils(
          "",
          "You must have to be a subscribed user to avail this feature.",
          "Ok",
          "Cancel"
        );
      } else {
        navigate("/redeemdashboard");
        localStorage.setItem("CardNumberVerified", "Redeem");
        localStorage.setItem("redeemPartnerId", partnerId);
      }
    } else {
      navigate("/login");
    }
  };

  const handleAddressClick = () => {
    const mapUrl = `https://www.google.com/maps?q=${partnerDetails?.latitude},${partnerDetails?.longitude}`;
    window.open(mapUrl, "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const exclusiveOffers = packageData.filter(
    (exclusiveOffersPackage) => exclusiveOffersPackage.isCustomized === 1
  );
  const packageItems = packageData.filter(
    (packageItemsPackage) => packageItemsPackage.isCustomized === 0
  );

  return (
    <>
      {partnerDetails == null ? (
        <NotFoundPage />
      ) : (
        <PartnerDetailsPageContainer>
          <Header />
          <div className="allContent">
            <Container>
              <Row>
                <Col lg={7}>
                  <Card className="customCard">
                    <Card.Body>
                      <div className="profileHead">
                        <div className="d-flex align-items-center justify-content-center">
                          <div className="imgContent">
                            <img
                              className="profileImg"
                              src={partnerDetails?.profilePhoto || defaultImg}
                              alt="profileimg"
                            />
                          </div>
                          <span className="profileName">
                            {partnerDetails?.name}
                          </span>
                        </div>
                      </div>
                      <div className="profileContent">
                        <div>
                          <span className="info">Partner Information</span>
                        </div>
                        <div className="mt-2">
                          <div>
                            <IoMdMail color="#f7851c" size={20} />
                            <span className="m-2">{partnerDetails?.email}</span>
                          </div>
                          <div className="mt-1">
                            <IoIosCall color="#f7851c" size={20} />
                            <span className="m-2">
                              {partnerDetails?.mobile}
                            </span>
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={handleAddressClick}
                            className="mt-1"
                          >
                            <FaMapMarkerAlt color="#f7851c" size={20} />
                            <span className="m-2">
                              {partnerDetails?.address}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ borderBottom: 0 }}
                        className="profileContent"
                      >
                        <div>
                          <span className="info">How to Use</span>
                        </div>
                        <div className="mt-2">
                          {Parse(howtouseData[0]?.cmsPageContent || "")}
                        </div>
                      </div>

                      {/* <div className="faqContent">
                      <div>
                        <span className="info">FAQs</span>
                      </div>
                      <div className="mt-2">
                        {faqData?.map((faq) => (
                          <Accordian
                            key={faq.faqId}
                            header={faq.question}
                            body={faq.answer}
                          />
                        ))}
                      </div>
                    </div> */}
                    </Card.Body>
                  </Card>
                </Col>

                <Col lg={5}>
                  {!isMobile && (
                    <div>
                      <Card className="rd-card">
                        <Card.Body className="redeem-card">
                          <div className="redeem-btn">
                            <button onClick={handleRedeemNowBtn}>
                              Redeem Now
                            </button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )}

                  <div className="mt-2">
                    <Card className="offer-card">
                      <Card.Body>
                        <div className="offers">
                          <div>
                            <span className="offers-title">
                              Smaartcard Offers
                            </span>
                          </div>
                          <div className="mt-2">
                            {packageItems && packageItems.length > 0 ? (
                              packageItems.map((item) => (
                                <ul key={item.id}>
                                  {item.packageItems &&
                                  item.packageItems.length > 0 ? (
                                    item.packageItems.map((offer, index) => (
                                      <div
                                        className="d-flex  align-items-center mt-1"
                                        key={index}
                                      >
                                        <li key={index}>
                                          {offer.packageItemDescription}
                                        </li>
                                        -
                                        <span
                                          style={{
                                            backgroundColor:
                                              offer?.remainingPoints > 0 ||
                                              offer?.remainingPoints === -1
                                                ? "#f9841a"
                                                : "#024089",
                                            border: "1px solid black",
                                            borderRadius: "3px",
                                            color: "white",
                                            padding: "3px",
                                            border: "none",
                                            marginLeft: "5px",
                                          }}
                                          className="package-redeem-count"
                                        >
                                          {offer?.remainingPoints === -1
                                            ? "Unlimited"
                                            : offer?.remainingPoints === 0
                                            ? "Used"
                                            : offer?.remainingPoints}
                                        </span>
                                      </div>
                                    ))
                                  ) : (
                                    <li>No Smaartcard Offers Found</li>
                                  )}
                                </ul>
                              ))
                            ) : (
                              <ul>
                                <li>No Smaartcard Offers Found</li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="mt-2">
                    <Card className="offer-card">
                      <Card.Body>
                        <div className="offers">
                          <div>
                            <span className="offers-title">
                              Customized card Offers
                            </span>
                          </div>
                          <div className="mt-2">
                            {exclusiveOffers && exclusiveOffers.length > 0 ? (
                              exclusiveOffers.map((item) => (
                                <ul key={item.id}>
                                  {item.packageItems &&
                                  item.packageItems.length > 0 ? (
                                    item.packageItems.map((offer, index) => (
                                      <div className="d-flex  align-items-center mt-1">
                                        <li key={index}>
                                          {offer.packageItemDescription}
                                        </li>
                                        -
                                        <span
                                          style={{
                                            backgroundColor:
                                              offer?.remainingPoints > 0 ||
                                              offer?.remainingPoints === -1
                                                ? "#f9841a"
                                                : "#024089",
                                            border: "1px solid black",
                                            borderRadius: "3px",
                                            color: "white",
                                            padding: "3px",
                                            border: "none",
                                            marginLeft: "5px",
                                          }}
                                          className="package-redeem-count"
                                        >
                                          {offer?.remainingPoints === -1
                                            ? "Unlimited"
                                            : offer?.remainingPoints === 0
                                            ? "Used"
                                            : offer?.remainingPoints}
                                        </span>
                                      </div>
                                    ))
                                  ) : (
                                    <li>No Customized card Offers Found</li>
                                  )}
                                </ul>
                              ))
                            ) : (
                              <ul>
                                <li>No Customized card Offers Found</li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  <div className="mt-2">
                    <Card className="tac-card">
                      <Card.Body>
                        <div className="tac">
                          <div>
                            <span className="tac-title">
                              Terms and Conditions
                            </span>
                          </div>
                          <div className="mt-2">
                            {tcData?.length > 0 ? (
                              tcData?.map((tcObj) => {
                                return (
                                  <li>{tcObj?.termsAndConditionDescription}</li>
                                );
                              })
                            ) : (
                              <li>No Records Found</li>
                            )}
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>

                  {isMobile && isRespon && (
                    <div className="mt-2">
                      <Card className="rd-card">
                        <Card.Body className="redeem-card">
                          <div className="redeem-btn">
                            <button onClick={handleRedeemNowBtn}>
                              Redeem Now
                            </button>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
          <Footer />
        </PartnerDetailsPageContainer>
      )}
    </>
  );
};

export default PartnerDetailsPage;
