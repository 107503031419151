import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CompanyLogo from "../../components/Logo/CompanyLogo";
import logo from "../../assets/images/logo.png";
import { ForgetPasswordContainer } from "./ForgetPasswordContainer";
import Input from "../../components/Input/Input";
import useResponsive from "../../Hooks/useResponsive";
import Footer from "../../components/Footer/Footer";
import OtpInputBox from "../../components/OtpInputBox/OtpInputBox";
import { Link, useNavigate } from "react-router-dom";
import { toastMessage } from "../../utils/toastUtils";
import { smaartCardUrl } from "../../constant";
import { axiosFunction } from "../../axiosFunction";
import { setPartnerData } from "../../store/Partner/PartnerSlice";
import { useDispatch, useSelector } from "react-redux";

const ForgetPassword = () => {
  const { isMobile, isRespon } = useResponsive();

  const [state, setState] = useState("fp");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const partnerData = useSelector((state) => state.partnerData);

  const [otpValue, setOtpValue] = useState("");

  const handleOtpChange = (otp) => {
    setOtpValue(otp);
  };

  const mobileNumberSchema = yup.object().shape({
    mobileNo: yup
      .string()
      .required("Please Enter Mobile Number")
      .matches(/^(\+91[-\s]?)?[0]?[6789]\d{9}$/, "Enter Valid Mobile Number")
      .max(10, "Enter Valid Mobile Number"),
  });

  const passwordSchema = yup.object().shape({
    newPassword: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .test("hasNumber", "Password must contain at least one number", (value) =>
        /\d/.test(value)
      )
      .test(
        "hasUppercase",
        "Password must contain at least one uppercase letter",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "hasLowercase",
        "Password must contain at least one lowercase letter",
        (value) => /[a-z]/.test(value)
      )
      .test(
        "hasSpecialChar",
        "Password must contain at least one special character",
        (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
      )
      .required("New Password is required"),
    confirmPassword: yup
      .string()
      .min(8, "Password must be at least 8 characters long")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match")
      .test("hasNumber", "Password must contain at least one number", (value) =>
        /\d/.test(value)
      )
      .test(
        "hasUppercase",
        "Password must contain at least one uppercase letter",
        (value) => /[A-Z]/.test(value)
      )
      .test(
        "hasLowercase",
        "Password must contain at least one lowercase letter",
        (value) => /[a-z]/.test(value)
      )
      .test(
        "hasSpecialChar",
        "Password must contain at least one special character",
        (value) => /[!@#$%^&*(),.?":{}|<>]/.test(value)
      )
      .required("Confirm Password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    defaultValues: {
      mobileNo: "",
    },
    resolver: yupResolver(mobileNumberSchema),
    mode: "onChange",
  });

  const {
    register: registerPassword,
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword },
    control: controlPassword,
  } = useForm({
    defaultValues: {
      newPassword: "",
      confirmPassword: "",
    },
    resolver: yupResolver(passwordSchema),
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/general/otp/send`,
      "POST",
      { phone: data.mobileNo }
    );

    if (res?.data?.status) {
      setState("otp");
      toastMessage(res?.data?.message, "success");
    } else {
      setState("fp");
      toastMessage(res?.data?.message, "error");
    }
  };

  const handleResendLink = async () => {
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/general/otp/send`,
      "POST",
      { phone: getValues("mobileNo") }
    );

    if (res?.data?.status) {
      setOtpValue("");
      toastMessage(res?.data?.message, "success");
    } else {
      toastMessage(res?.data?.message, "error");
    }
  };

  const handleSubmitOtp = async () => {
    const otpRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/general/verifyotp?phone=${getValues(
        "mobileNo"
      )}&otp=${otpValue}`,
      "GET",
      null
    );

    if (otpRes?.data?.status) {
      if (otpRes?.data?.response) {
        setState("changePass");
        dispatch(setPartnerData(otpRes?.data?.response));
        toastMessage(otpRes?.data?.message, "success");
      } else {
        setState("otp");
        toastMessage(otpRes?.data?.message, "error");
      }
    } else {
      setState("otp");
      toastMessage(otpRes?.data?.message, "error");
    }
  };

  const onPasswordSubmit = async (data) => {
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/partner/reset`,
      "POST",
      {
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
        phone: getValues("mobileNo"),
      }
    );

    if (res?.data?.status) {
      navigate("/partnerRegistration");
      toastMessage(res?.data?.message, "success");
    } else {
      navigate("/partnerRegistration");
      toastMessage(res?.data?.message, "error");
    }
  };

  return (
    <ForgetPasswordContainer isRespon={isRespon}>
      <div className="login-parent">
        <div className="login-modal">
          <div className="login-header">
            {state === "fp" ? (
              <>
                <div className="text-center">
                  <CompanyLogo companyLogo={logo} />
                </div>
                <h1 className="title-name">Forget Password</h1>

                <div className="main-login-container">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                      type={"number"}
                      placeholder={"Phone Number"}
                      control={control}
                      register={register}
                      nameField={"mobileNo"}
                      errors={errors}
                    />
                    <button type="submit" className="otp-btn">
                      Send OTP
                    </button>
                  </form>
                </div>
              </>
            ) : state === "otp" ? (
              <>
                <div className="otp-modal">
                  <div className="otp-title">
                    <h2 className="title-name">Account Confirmation</h2>
                    <div>
                      <span>
                        We just sent you an confirmation code on your phone.
                      </span>
                      <span>Please enter the code to verify your account.</span>
                    </div>
                  </div>

                  <OtpInputBox
                    onOtpChange={handleOtpChange}
                    otpValue={otpValue}
                  />
                  <div className="main-login-container">
                    <button onClick={handleSubmitOtp} className="otp-btn">
                      Submit
                    </button>
                  </div>
                </div>
                <div className="partner-link">
                  <p>Didn't receive the code? </p>
                  <Link onClick={handleResendLink}>Resend</Link>
                </div>
              </>
            ) : (
              <>
                <h1 className="title-name">Change Password</h1>

                <div className="main-login-container">
                  <form onSubmit={handleSubmitPassword(onPasswordSubmit)}>
                    <div className="mt-2">
                      <Input
                        type={"password"}
                        placeholder={"New Password"}
                        control={controlPassword}
                        register={registerPassword}
                        nameField={"newPassword"}
                        errors={errorsPassword}
                        label="New Password"
                      />
                    </div>

                    <div className="mt-2">
                      <Input
                        type={"password"}
                        placeholder={"Confirm Password"}
                        control={controlPassword}
                        register={registerPassword}
                        nameField={"confirmPassword"}
                        errors={errorsPassword}
                        label="Confirm Password"
                      />
                    </div>
                    <button type="submit" className="otp-btn">
                      Submit
                    </button>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {!isMobile && <Footer />}
    </ForgetPasswordContainer>
  );
};

export default ForgetPassword;
