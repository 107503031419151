import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { useSelector } from "react-redux";
import { RedeemProfileContainer } from "./RedeemProfileContainer";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import defaultImg from "../../../assets/images/imagePlaceholder.png";
import { smaartCardUrl } from "../../../constant";
import { axiosFunction } from "../../../axiosFunction";
import { IoChevronBack } from "react-icons/io5";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { toastMessage } from "../../../utils/toastUtils";
import AccessDeniedPage from "../../AccessDeniedPage/AccessDeniedPage";
import { GoDotFill } from "react-icons/go";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";

const RedeemDashboardProfile = () => {
  const userData = useSelector((state) => state.userData);

  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const redeemPartnerId = localStorage.getItem("redeemPartnerId");

  const partnerId = localStorage.getItem("partnerId");

  const role = localStorage.getItem("role");

  const [loading, setLoading] = useState("");
  const [itemCheck, setItemCheck] = useState([]);
  const [subscribeData, setSubscribeData] = useState({});
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [ownedPackages, setOwnedPackages] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [value, setValue] = useState(userData.pointBalance);
  const [partnerData, setPartnerData] = useState({});
  const [validationOfisCustomization, setValidationOfisCustomization] =
    useState(0);

  const [loadedRedeem, setLoadedRedeem] = useState(false);

  const handlePackageClick = async (packageObj, iscustomization) => {
    if (
      iscustomization == 1 &&
      localStorage.getItem("isCustomizedPartner") == 0
    ) {
      Swal.fire("You are not eligible to redeem this offer");
    } else {
      setValidationOfisCustomization(iscustomization);
      const packageRes = await axiosFunction(
        `${smaartCardUrl}/api/v1/dfordr/master/package/patients/${userData?.patientId}/packages/${packageObj?.packageId}/partner/${redeemPartnerId}`,
        "GET",
        null
      );

      if (packageRes?.data?.status) {
        setSelectedPackage(packageRes?.data?.response);
      } else {
        setSelectedPackage(null);
      }
    }
  };

  const handleBack = () => {
    setSelectedPackage(null);
    setIsCheckboxChecked(false);
    setItemCheck([]);
  };

  const handleCheckItem = (e, itemObj) => {
    const { checked } = e.target;

    setItemCheck((prevItem) => {
      const existingItem = prevItem.find(
        (item) => item.packageItemId === itemObj.packageItemId
      );

      if (checked) {
        if (existingItem) {
          return prevItem.map((item) =>
            item.packageItemId === itemObj.packageItemId
              ? { ...item, checked: true }
              : item
          );
        } else {
          return [...prevItem, { ...itemObj, checked: true }];
        }
      } else {
        return prevItem.filter(
          (item) => item.packageItemId !== itemObj.packageItemId
        );
      }
    });
  };
  const handleValueChange = (change) => {
    setValue((prev) => Math.max(0, prev + change));
  };

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
    if (isCheckboxChecked) {
      setValue(0);
    }
  };
  const getSubscriptionDetails = async () => {
    setLoading("subscriptionData");
    const subRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/subcription/subscriptionByPatient/${
        role === "user" ? localStorage.getItem("userId") : userData?.patientId
      }`,
      "GET",
      null
    );

    if (subRes?.data?.status) {
      setLoading("");
      setSubscribeData(subRes?.data?.response);
    } else {
      setSubscribeData(subRes?.data);
    }
  };

  const getOwnedPackages = async () => {
    setLoading("packages");
    const res = await axiosFunction(
      `${smaartCardUrl}/api/v1/dfordr/master/package/partnerPackagesSmartCard/${
        role === "user" ? redeemPartnerId : partnerId
      }/${
        role === "user" ? localStorage.getItem("userId") : userData?.patientId
      }`,
      "GET",
      null
    );

    if (res?.data?.status) {
      setLoading("");
      setOwnedPackages(res?.data?.response);
    } else {
      setLoading("");
      setOwnedPackages([]);
    }
  };

  const handleRedeemNowBtn = async () => {
    const isSelf = localStorage.getItem("role") === "user" ? 0 : 1;
    const itemPayload = itemCheck?.map((item) => ({
      packageItemId: item?.packageItemId,
      packageItemValue: item?.packageItemValue,
      redeemCount: item?.remainingPoints === -1 ? -1 : 1,
    }));
    const coinValue = isCheckboxChecked === true ? value : 0;

    setLoadedRedeem(true);

    try {
      const res = await axiosFunction(
        `${smaartCardUrl}/api/v1/redemptions/smartCard/users/${
          role === "user" ? localStorage.getItem("userId") : userData?.patientId
        }/redeemtion/${isSelf}/${coinValue}`,
        "POST",
        itemPayload
      );

      if (res?.data?.status) {
        setLoadedRedeem(false);
        toast.success(res?.data?.message);
        role === "user"
          ? navigate("/redeemed-packages-user")
          : navigate("/redeemLogs");
        localStorage.removeItem("redeemPartnerId");
      } else {
        setLoadedRedeem(false);
        toast.error(res?.data?.message);
      }
    } catch (error) {
      setLoadedRedeem(false);
      toast.error("Something went wrong!");
    }
  };

  const showRedeemModal = () => {
    if (validationOfisCustomization === 0) {
      MySwal.fire({
        title: "Are you sure?",
        html: '<p style="font-size: 14px; font-weight: bold;">Do you want to redeem this offer?</p>',
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Redeem",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary",
        },

        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          handleRedeemNowBtn(); // Call the API if confirmed
        }
      });
      return; // Exit the function after showing the simple message
    }

    if (isCheckboxChecked) {
      MySwal.fire({
        title: "Are you sure?",
        // text: `Do you want to Deduct ${value} point? `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Redeem",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-secondary",
        },

        input: "text",
        inputPlaceholder: "Type your remark here...",
        inputAttributes: {
          "aria-label": "Type your remark here",
        },
        buttonsStyling: false,
        html: `<p style="color: green; font-size: 18px; font-weight: bold;">Do you want to Deduct ${value} point?</p>`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          handleRedeemNowBtn(); // Call the API if confirmed
        }
      });
      return;
    }
    // const isSelf = localStorage.getItem("role") === "user" ? 0 : 1;
    const Role = localStorage.getItem("role") === "user" ? 1 : 0;

    MySwal.fire({
      title: "Are you sure?",
      text: "Do you want to redeem now?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Redeem",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
      buttonsStyling: false,
      html: Role
        ? '<p style="color: red; font-size: 14px; font-weight: bold;">Note: If you redeem this offer yourself, you will receive **zero redeem points **.</p><p style="font-size: 14px; font-weight: bold;">If you want to earn redeem points, please ask your partner to redeem the offer. Thank you!</p>'
        : '<p style="color: green; font-size: 14px; font-weight: bold;">You are eligible to redeem this offer.</p>',
    }).then((result) => {
      if (result.isConfirmed) {
        handleRedeemNowBtn(); // Call the API if confirmed
      }
    });
  };

  const getPartnerDetails = async () => {
    const partnerRes = await axiosFunction(
      `${smaartCardUrl}/api/v1/partner/${redeemPartnerId}/?patientId=${userData.patientId}`,
      "GET",
      null
    );

    if (partnerRes?.data?.status) {
      setPartnerData(partnerRes?.data?.response);
      localStorage.setItem(
        "isCustomizedPartner",
        partnerRes?.data?.response?.isCustomized
      );
      localStorage.setItem(
        "isManagePointdPartner",
        partnerRes?.data?.response?.isManageWallet
      );
    } else {
      toastMessage(partnerRes?.data?.message, "error");
      setPartnerData({});
    }
  };

  useEffect(() => {
    getSubscriptionDetails();
    getOwnedPackages();
    getPartnerDetails();
  }, []);

  if (!redeemPartnerId) {
    return <AccessDeniedPage />;
  }

  if (loading === "packages" || loading === "subscriptionData") {
    return <Loader />;
  }

  const handleSubmitCoin = async () => {
    const Role = localStorage.getItem("role") === "user" ? 1 : 0;
    MySwal.fire({
      title: "Are you sure?",
      // text: `Do you want to Deduct ${value} point? `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Redeem",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },

      input: "text",
      inputPlaceholder: "Type your remark here...",
      inputAttributes: {
        "aria-label": "Type your remark here",
      },
      buttonsStyling: false,
      html: `<p style="color: green; font-size: 18px; font-weight: bold;">Do you want to Deduct ${value} point?</p>`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await axiosFunction(
          `${smaartCardUrl}/api/v1/redemptions/redeemptionPoint?reedemPoint=${value}&patientId=${
            userData.patientId
          }&partnerId=${localStorage.getItem("redeemPartnerId")}`,
          "POST",
          { remarks: result.value }
          // result.value
        );

        if (res?.data?.status) {
          toastMessage(res?.data?.message, "success");
          localStorage.setItem("isManagePointdPartner", 1);
          navigate("/partner-profile");
        } else {
          toastMessage(res?.data?.message, "error");
        }
      }
    });
  };

  return (
    <RedeemProfileContainer>
      <Header />
      <div className="allContent">
        <Container>
          <Row>
            <Col lg={7}>
              <div className="h-100">
                <Card className="customCard">
                  <Card.Body>
                    <div className="profileHead">
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="imgContent">
                          <img
                            className="profileImg"
                            src={
                              role === "partner"
                                ? userData?.logo || defaultImg
                                : partnerData?.profilePhoto || defaultImg
                            }
                            alt="profileimg"
                          />
                        </div>
                        <span className="profileName">
                          {role === "partner"
                            ? userData?.name
                            : partnerData?.name}
                        </span>
                      </div>
                    </div>
                    <div className="profileContent">
                      <div className="pc">
                        <span className="pct mb-2">Email</span>:
                        <span className="pcc">
                          {role === "partner"
                            ? userData?.email || "-"
                            : partnerData?.email || "-"}
                        </span>
                      </div>
                      <div className="pc">
                        <span className="pct mb-2">Mobile No.</span>:
                        <span className="pcc">
                          {role === "partner"
                            ? userData?.phone || "-"
                            : partnerData?.mobile || "-"}
                        </span>
                      </div>

                      <div className="pc">
                        <span className="pct">City</span>:
                        <span className="pcc">
                          {role === "partner"
                            ? userData?.city || "-"
                            : partnerData?.city || "-"}
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>

            <Col lg={5} className="mt-lg-0 mt-3">
              <div className="sd">
                <Card className="customCard">
                  <Card.Body>
                    <div className="profileHead">
                      <span className="profileName">Card Details</span>
                    </div>
                    <div className="profile1Content">
                      <div className="pc">
                        <span className="pct mb-2">Current Status</span>:
                        <span className="pcc">
                          {!subscribeData?.currentStatus && (
                            <GoDotFill
                              color={
                                subscribeData?.currentStatus === 0
                                  ? "green"
                                  : "red"
                              }
                            />
                          )}
                          {subscribeData?.currentStatus === 0
                            ? "Active"
                            : subscribeData?.currentStatus === 1
                            ? "Inactive"
                            : "-"}
                        </span>
                      </div>
                      <div className="pc">
                        <span className="pct mb-2">Name</span>:
                        <span className="pcc">
                          {subscribeData?.subscriptionMasterName || "-"}
                        </span>
                      </div>

                      <div className="pc">
                        <span className="pct mb-2">Card Number</span>:
                        <span className="pcc">
                          {subscribeData?.cardNumber || "-"}
                        </span>
                      </div>

                      <div className="pc">
                        <span className="pct mb-2">Start Date</span>:
                        <span className="pcc">
                          {subscribeData?.startTime || "-"}
                        </span>
                      </div>

                      <div className="pc">
                        <span className="pct mb-2">End Date</span>:
                        <span className="pcc">
                          {subscribeData?.endTime || "-"}
                        </span>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="mt-lg-0 mt-3 kpLastSection">
              <div className="content">
                {selectedPackage ? (
                  <>
                    <Card className="mt-2">
                      <Card.Body>
                        <Card.Title>
                          <div className="d-flex align-items-center">
                            <button onClick={handleBack} className="btn">
                              <IoChevronBack />
                            </button>
                            <h2 className="card-title">Offers</h2>
                          </div>
                        </Card.Title>
                        <div className="row">
                          <Card.Text className="col-12">
                            {selectedPackage.length > 0 ? (
                              selectedPackage.map((item) => (
                                <div
                                  key={item.packageItemId}
                                  className="package-item m-2"
                                >
                                  <div className="package-item-content">
                                    <Row>
                                      <Col lg={6}>
                                        <div className="d-flex align-items-start">
                                          <div>
                                            <input
                                              disabled={
                                                (item?.isCustomized === 1 &&
                                                  localStorage.getItem(
                                                    "isCustomized"
                                                  ) === "0") ||
                                                item?.remainingPoints === 0
                                              }
                                              className="checkbox-input"
                                              type="checkbox"
                                              checked={itemCheck.some(
                                                (pItem) =>
                                                  pItem.packageItemId ===
                                                    item.packageItemId &&
                                                  pItem.checked
                                              )}
                                              onChange={(e) =>
                                                handleCheckItem(e, item)
                                              }
                                            />
                                          </div>
                                          <div>
                                            {item?.packageItemDescription}
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6}>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div>
                                            <span className="package-title">
                                              Price:{" "}
                                            </span>
                                            {item?.packageItemValue}
                                          </div>
                                          <div>
                                            {localStorage.getItem(
                                              "isCustomizedPartner"
                                            ) === "1" &&
                                              localStorage.getItem(
                                                "isManagePointdPartner"
                                              ) === "1" &&
                                              validationOfisCustomization ===
                                                1 && (
                                                <div
                                                  style={{
                                                    backgroundColor: "#f9841a",
                                                    color: "white",
                                                    padding: "5px",
                                                    borderRadius: "4px",
                                                  }}
                                                >
                                                  <span className="package-title">
                                                    Point:{" "}
                                                  </span>
                                                  {item?.redeemPoint}
                                                </div>
                                              )}
                                          </div>
                                          <div>
                                            <span
                                              style={{
                                                backgroundColor:
                                                  item?.remainingPoints ===
                                                    null ||
                                                  item?.remainingPoints > 0 ||
                                                  item?.remainingPoints === -1
                                                    ? "#024089"
                                                    : "#f9841a",
                                              }}
                                              className="package-redeem-count"
                                            >
                                              {item?.remainingPoints === null
                                                ? item?.redeemCount
                                                : item?.remainingPoints === -1
                                                ? "Unlimited"
                                                : item?.remainingPoints === 0
                                                ? "Used"
                                                : item?.remainingPoints}
                                            </span>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div
                                className="m-4"
                                style={{
                                  backgroundColor: "#f0f7ff",
                                  padding: "10px",
                                }}
                              >
                                No Smaart Card Offers Found.
                              </div>
                            )}
                          </Card.Text>
                        </div>

                        {localStorage.getItem("role") !== "user" &&
                          validationOfisCustomization === 1 && (
                            <>
                              <div
                                style={{
                                  marginBottom: "10px",
                                  marginLeft: "10px",
                                }}
                              >
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                  />{" "}
                                  Do you want to deduct the point?
                                </label>
                              </div>

                              <div className="d-flex justify-content-center align-items-center">
                                <Button
                                  // className="btn btn-outline-secondary"
                                  style={{
                                    backgroundColor: "#024089",
                                    color: "white",
                                  }}
                                  onClick={() => handleValueChange(-1)}
                                  disabled={!isCheckboxChecked}
                                >
                                  -
                                </Button>
                                <input
                                  type="number"
                                  className="form-control mx-2"
                                  value={value}
                                  onChange={(e) =>
                                    setValue(Number(e.target.value))
                                  }
                                  disabled={!isCheckboxChecked}
                                  min={0}
                                  style={{ width: "80px", textAlign: "center" }}
                                />
                                <Button
                                  style={{
                                    backgroundColor: "#024089",
                                    color: "white",
                                  }}
                                  onClick={() => handleValueChange(1)}
                                  disabled={value === userData.pointBalance}
                                >
                                  +
                                </Button>
                              </div>
                            </>
                          )}
                        <div style={{ width: "100%", textAlign: "center" }}>
                          <div style={{ marginTop: "20px" }}>
                            <Button
                              style={{
                                backgroundColor: "#024089",
                                border: "1px solid #024089",
                              }}
                              onClick={showRedeemModal}
                              className="subscribeBtn"
                              disabled={itemCheck?.length === 0 || loadedRedeem}
                            >
                              Redeem Now
                            </Button>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </>
                ) : localStorage.getItem("CardNumberVerified") === "Redeem" ? (
                  <>
                    <Card className="mt-2">
                      <Card.Body>
                        <Card.Title>
                          <h2 className="card-title">Smaart packages</h2>
                        </Card.Title>
                        <Card.Text className="card-content d-flex flex-wrap">
                          {ownedPackages?.length > 0 ? (
                            ownedPackages.some(
                              (packageObj) => packageObj.isCustomized === 0
                            ) ? (
                              ownedPackages
                                .filter(
                                  (packageObj) => packageObj.isCustomized === 0
                                )
                                .map((packageObj) => (
                                  <div
                                    onClick={() =>
                                      handlePackageClick(packageObj, 0)
                                    }
                                    key={packageObj.packageId}
                                    className="m-sm-4 m-2 kppackage"
                                  >
                                    {packageObj?.packageName}
                                  </div>
                                ))
                            ) : (
                              <div
                                className="m-4"
                                style={{
                                  backgroundColor: "#f0f7ff",
                                  padding: "10px",
                                }}
                              >
                                No Packages Found.
                              </div>
                            )
                          ) : (
                            <div
                              className="m-4"
                              style={{
                                backgroundColor: "#f0f7ff",
                                padding: "10px",
                              }}
                            >
                              No Packages Found.
                            </div>
                          )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card className="mt-2">
                      <Card.Body>
                        <Card.Title>
                          <h2 className="card-title">Customize packages</h2>
                        </Card.Title>
                        <Card.Text className="card-content d-flex flex-wrap">
                          {ownedPackages?.length > 0 ? (
                            ownedPackages.some(
                              (packageObj) => packageObj.isCustomized === 1
                            ) ? (
                              ownedPackages
                                .filter(
                                  (packageObj) => packageObj.isCustomized === 1
                                )
                                .map((packageObj) => (
                                  <div
                                    onClick={() => {
                                      handlePackageClick(packageObj, 1);
                                    }}
                                    key={packageObj.packageId}
                                    className="m-sm-4 m-2 kppackage"
                                  >
                                    {packageObj?.packageName}
                                  </div>
                                ))
                            ) : (
                              <div
                                className="m-4"
                                style={{
                                  backgroundColor: "#f0f7ff",
                                  padding: "10px",
                                }}
                              >
                                No Packages Found.
                              </div>
                            )
                          ) : (
                            <div
                              className="m-4"
                              style={{
                                backgroundColor: "#f0f7ff",
                                padding: "10px",
                              }}
                            >
                              No Packages Found.
                            </div>
                          )}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </>
                ) : (
                  <>
                    <Card style={{ marginTop: "10px" }}>
                      <div
                        className="mt-4 text-center"
                        style={{ marginBottom: "5px" }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <h5 style={{ marginRight: "5px" }}>
                            Do you want to deduct the point?
                          </h5>
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => handleValueChange(-1)}
                          >
                            -
                          </button>
                          <input
                            type="number"
                            className="form-control mx-2"
                            value={value}
                            onChange={(e) => setValue(Number(e.target.value))}
                            style={{ width: "80px", textAlign: "center" }}
                            min={0}
                          />
                          <button
                            className="btn btn-outline-secondary"
                            onClick={() => handleValueChange(1)}
                            disabled={userData.pointBalance === value}
                          >
                            +
                          </button>
                          <Button
                            style={{
                              backgroundColor: "#024089",
                              marginLeft: "5px",
                            }}
                            onClick={() => handleSubmitCoin()}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </RedeemProfileContainer>
  );
};

export default RedeemDashboardProfile;
